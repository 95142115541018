<template>
  <div class="user_list-content-row" @click="$emit('openUser', data.id)">
    <div class="table--col col-1">
      <p v-html="data.id"></p>
    </div>
    <div class="table--col col-1">
      <p v-html="data.nickname ? data.nickname : '-'"></p>
    </div>
    <div class="table--col col-5">
      <p v-html="data.email"></p>
    </div>
    <div class="table--col col-1">
      <p>{{ data.pin_status ? 'Онлайн' : 'Оффлайн' }}</p>
    </div>
    <div class="table--col ml-20 col-1">
      <button
        @click.stop="handleChangeUser"
        :class="['btn-white--border', { disabled: data.is_banned }]"
      >
        {{ label }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

export default {
  name: 'UserListRow',
  methods: {
    ...mapMutations(['openModal', 'closeModal']),
    ...mapActions(['banUser', 'getUsers']),
    handleChangeUser() {
      this.openModal({
        nameModal: 'ConfirmUserChange',
        dataModal: {
          label: this.label,
          nameUser: this.data.name ? this.data.name : this.data.email,
          type: !this.data.is_banned,
          callbackModal: days => {
            this.closeModal();
            this.banUser({
              user_id: this.data.id,
              days: !this.data.is_banned ? days : 0,
              type: !this.data.is_banned,
              successCallback: () => {
                this.getUsers();
              },
            });
          },
        },
      });
    },
  },
  computed: {
    label() {
      if (this.data.is_banned) {
        return 'Востановить';
      }
      return 'Заглушить';
    },
  },
  props: {
    data: Object,
  },
};
</script>

<style scoped></style>

<template>
  <div class="user_lists">
    <h1>Пользователи</h1>
    <ul class="lists">
      <template v-for="tab in tabs">
        <li
          @click="activeTab = tab.id"
          :class="[
            'lists-link',
            { 'lists-link--active': activeTab === tab.id },
          ]"
          :key="tab.id"
        >
          {{ tab.label }}
        </li>
      </template>
    </ul>
    <SearchBar class="search_bar" @input="e => (searchText = e)" :hints="[]" />
    <UsersList :users="usersList" />
    <!--    <sliding-pagination-->
    <!--        :current="currentPage"-->
    <!--        :total="totalPages"-->
    <!--        @page-change="pageChangeHandler"-->
    <!--    ></sliding-pagination>-->
    <div v-if="isLoading">
      <loader
        object="#fff"
        color1="#435A64"
        color2="#ECEFF0"
        size="5"
        speed="2"
        bg="#343a40"
        objectbg="#999793"
        opacity="80"
        name="circular"
      ></loader>
    </div>
  </div>
</template>

<script>
import SearchBar from '@/components/ui/SearchBar';
import UsersList from '@/components/UsersList';
import { mapActions, mapState } from 'vuex';
import SlidingPagination from 'vue-sliding-pagination';
import { loader } from 'vue-ui-preloader';

export default {
  name: 'UsersLists',
  data() {
    return {
      activeTab: 0,
      currentPage: 1,
      totalPages: 10,
      tabs: [
        {
          label: 'Активные пользователи',
          id: 0,
        },
        {
          label: 'Черный список',
          id: 1,
        },
      ],
      searchText: '',
    };
  },
  methods: {
    ...mapActions(['getUsers', 'searchUser']),
    pageChangeHandler(selectedPage) {
      this.currentPage = selectedPage;
      this.$router.push(`?page=${selectedPage}`);
    },
  },
  computed: {
    ...mapState({
      users: state => state.users,
      isLoading: state => state.isLoading,
    }),
    usersList() {
      if (this.users && this.users.length) {
        return this.users.filter(user => user.is_banned === this.activeTab);
      }

      return [];
    },
  },
  watch: {
    searchText() {
      this.searchUser({
        search: this.searchText,
        type: this.activeTab,
      });
    },
  },
  mounted() {
    this.getUsers();
  },
  components: {
    SearchBar,
    UsersList,
    SlidingPagination,
    loader,
  },
};
</script>

<style lang="scss" scoped>
::v-deep .c-sliding-pagination__list {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  li {
    list-style: none;
    margin: 0 2px;
    a {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #435a64;
      text-decoration: none;
      padding: 8px;
    }
  }
}
</style>

<template>
  <div class="user_list">
    <TableHeader :data="tableHead" />
    <div class="user_list-content">
      <UserListRow
        v-for="(row, key) in users"
        @openUser="e => $router.push(`id=${e}`)"
        :data="row"
        :key="key"
      />
    </div>
  </div>
</template>

<script>
import UserListRow from '@/components/ui/UserListRow';
import TableHeader from '@/components/ui/TableHeader';

export default {
  name: 'UsersList',
  data() {
    return {
      rowData: [
        {
          id: 23232,
          name: 'John Doe',
          mail: 'john@gmail.com',
          status: 'Онлайн',
        },
        {
          id: 2,
          name: 'John Doe',
          mail: 'john@gmail.com',
          status: 'Онлайн',
        },
      ],
      tableHead: [
        {
          col: 1,
          label: 'ID',
        },
        {
          col: 1,
          label: 'Имя',
        },
        {
          col: 5,
          label: 'E-mail',
        },
        {
          col: 2,
          label: 'Статус',
        },
      ],
    };
  },
  components: {
    UserListRow,
    TableHeader,
  },
  props: {
    users: Array,
  },
};
</script>

<style lang="scss"></style>
